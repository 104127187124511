import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/** Styles */
import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Main.scss";

/** Context */
// import AppContext from "@contexts/AppContext";
// import useInitialState from "@hooks/useInitialState";

/** Configs FontAwasome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { setUser } from "../store/slices/user.slice";
import { decodeToken } from "../helpers/auth.helper";
import { sortUserMenus } from "../helpers/user.helper";

/** Pages */
const ShipmentApp = React.lazy(() => import("../pages/ShipmentApp"));
const Shipment = React.lazy(() => import("../pages/Shipment"));
const Company = React.lazy(() => import("../pages/Company"));
const User = React.lazy(() => import("../pages/User"));
const ChangeUserPassword = React.lazy(() =>
  import("../pages/ChangeUserPassword")
);
const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Role = React.lazy(() => import("../pages/Role"));
const ProtectedRoute = React.lazy(() => import("../containers/ProtectedRoute"));

library.add(fab, fas);

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const token = auth.token;
  useEffect(() => {
    if (token) {
      const obj = decodeToken(token);
      const menus = sortUserMenus(obj.menus);
      dispatch(
        setUser({
          username: obj.username,
          roles: obj.roles,
          menus: menus,
        })
      );
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<p>Cargando ....</p>}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/users/change-password"
            element={
              <ProtectedRoute>
                <ChangeUserPassword />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/roles"
            element={
              <ProtectedRoute>
                <Role />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/companies"
            element={
              <ProtectedRoute>
                <Company />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/shipments"
            element={
              <ProtectedRoute>
                <Shipment />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/public/app/shipment/:trackCode/:shipmentId"
            element={<ShipmentApp />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
