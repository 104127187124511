import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_API } from "../shared/consts";
import { redirectLogin } from "../utils/functions.util";
/** THUNKS */
export const getRoleList = createAsyncThunk(
  "role/getRoleList",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${URL_API}/roles`, {
        withCredentials: true,
      });
      const response = res.data;
      return response;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
