import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import {
  createUser,
  deleteUser,
  getUserList,
  updateUser,
  changeUserPassword,
} from "../../services/user.service";
import { decodeToken, getToken } from "../../helpers/auth.helper";

const initialState = {
  user: null,
  roles: null,
  menus: null,
  isLoading: false,
  list: [],
  search: {},
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 15,
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUser(state, actions) {
      const hashToken = getToken();
      if (hashToken) {
        const jwtObj = decodeToken(hashToken);
        const sortMenu = jwtObj.menus.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
        toast.dismiss();
        toast.success(`Bienvenido ${jwtObj.username}`, OPTIONS_TOAST);
        return {
          ...state,
          user: jwtObj.username,
          roles: jwtObj.roles,
          menus: sortMenu,
          isLoading: false,
        };
      } else {
        return {
          ...state,
          user: null,
          menu: null,
          isLoading: false,
        };
      }
    },
    setUser(state, actions) {
      return {
        ...state,
        user: actions.payload.username,
        roles: actions.payload.roles,
        menus: actions.payload.menus,
        isLoading: false,
      };
    },
    clearMessages(state, actions) {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        isLoading: false,
      };
    },
    setCurrentPageList(state, actions) {
      return { ...state, currentPageList: actions.payload };
    },
    setSearchParams(state, actions) {
      return { ...state, search: actions.payload, currentPageList: 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data.list,
          currentPageList: data.meta.page,
          totalPageList: data.meta.total_page,
          sizePageList: data.meta.size,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });

    builder.addCase(createUser.pending, (state, action) => {
      toast.loading(`Creando usuario ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(updateUser.pending, (state, action) => {
      toast.loading(`Actualizando usuario ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(deleteUser.pending, (state, action) => {
      toast.loading(`Eliminando usuario ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(changeUserPassword.pending, (state, action) => {
      toast.loading(`Cambiando contraseña del usuario ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(changeUserPassword.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });
  },
});

export const {
  loadUser,
  setCurrentPageList,
  setSearchParams,
  clearMessages,
  setUser,
} = userSlice.actions;
export default userSlice.reducer;
