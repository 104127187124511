import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import {
  createUbigeo,
  deleteUbigeo,
  getUbigeoList,
  updateUbigeo,
} from "../../services/ubigeo.service";

const initialState = {
  isLoading: false,
  list: [],
  search: {},
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 1,
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const ubigeoSlice = createSlice({
  name: "ubigeo",
  initialState,
  reducers: {
    clearErrorMsg(state, actions) {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        isLoading: false,
      };
    },
    setCurrentPageList(state, actions) {
      return { ...state, currentPageList: actions.payload };
    },
    setSearchParams(state, actions) {
      return { ...state, search: actions.payload, currentPageList: 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUbigeoList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getUbigeoList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data.list,
          currentPageList: data.meta.page,
          totalPageList: data.meta.total_page,
          sizePageList: data.meta.size,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getUbigeoList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });

    builder.addCase(createUbigeo.pending, (state, action) => {
      toast.loading(`Creando ubigeo ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(createUbigeo.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(createUbigeo.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(updateUbigeo.pending, (state, action) => {
      toast.loading(`Actualizando ubigeo ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(updateUbigeo.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(updateUbigeo.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(deleteUbigeo.pending, (state, action) => {
      toast.loading(`Eliminando ubigeo ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(deleteUbigeo.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(deleteUbigeo.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });
  },
});

export const { setCurrentPageList, setSearchParams, clearErrorMsg } =
  ubigeoSlice.actions;
export default ubigeoSlice.reducer;
