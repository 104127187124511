export const TAG_TOKEN = "__token_slc";
export const URL_API = process.env.URL_API;
export const OPTIONS_TOAST = {
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  theme: "dark",
};
export const MAPS_API_KEY = process.env.MAPS_API_KEY;
export const GEOCODING_API_KEY = process.env.GEOCODING_API_KEY;

export const FILE_SERVER_URL = process.env.FILE_SERVER_URL;
export const TIME_ZONE_OFFSET  = Number(process.env.TIME_ZONE_OFFSET);