import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_API } from "../shared/consts";
import { redirectLogin } from "../utils/functions.util";

/** THUNKS */
export const getShipmentList = createAsyncThunk(
  "shipment/getShipmentList",
  async (values, { rejectWithValue }) => {
    try {
      const { page, size, search } = values;
      const filter = Object.entries(search);
      if (filter.length) {
        const res = await axios.post(
          `${URL_API}/shipments/search`,
          {
            filters: search,
            page: page,
            size: size,
          },
          {
            withCredentials: true,
          }
        );
        return res.data;
      }
      const res = await axios.get(
        `${URL_API}/shipments?page=${page}&size=${size}`,
        {
          withCredentials: true,
        }
      );
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const createShipment = createAsyncThunk(
  "shipment/createShipment",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${URL_API}/shipments`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const updateShipment = createAsyncThunk(
  "shipment/updateShipment",
  async (values, { rejectWithValue }) => {
    try {
      const { id } = values;
      const res = await axios.put(`${URL_API}/shipments/${id}`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const deleteShipment = createAsyncThunk(
  "shipment/deleteShipment",
  async (values, { rejectWithValue }) => {
    try {
      const { id } = values;
      const res = await axios.delete(`${URL_API}/shipments/${id}`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const changeShipmentStatus = createAsyncThunk(
  "shipment/changeShipmentStatus",
  async (values, { rejectWithValue }) => {
    try {
      const { id, attachments } = values;
      let data = new FormData();
      const keys = Object.keys(values);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key !== "id" && key !== "attachments") {
          data.append(key, values[key]);
        }
      }
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        data.append("attachments", attachment);
      }
      const res = await axios.post(
        `${URL_API}/shipments/${id}/status-change`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const bulkChangeShipmentStatus = createAsyncThunk(
  "shipment/bulkChangeShipmentStatus",
  async (values, { rejectWithValue }) => {
    try {
      // const { ids, attachments } = values;
      const res = await axios.post(
        `${URL_API}/shipments/status-change/bulk`,
        values,
        {
          withCredentials: true,
        }
      );
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const uploadShipments = createAsyncThunk(
  "shipment/uploadShipments",
  async (values, { rejectWithValue }) => {
    try {
      const { attachments } = values;
      let data = new FormData();
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        data.append("attachments", attachment);
      }
      const res = await axios.post(`${URL_API}/shipments/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
export const downloadShipments = createAsyncThunk(
  "shipment/downloadShipments",
  async (values, { rejectWithValue }) => {
    try {
      const { search } = values;
      const res = await axios.post(
        `${URL_API}/shipments/download`,
        {
          filters: search,
        },
        {
          responseType: "arraybuffer",
          withCredentials: true,
        }
      );
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
