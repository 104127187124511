import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_API } from "../shared/consts";
import { redirectLogin } from "../utils/functions.util";

/** THUNKS */
export const getUserList = createAsyncThunk(
  "user/getUserList",
  async (values, { rejectWithValue }) => {
    try {
      const { page, size, search } = values;
      const filter = Object.entries(search);
      if (filter.length) {
        const res = await axios.post(
          `${URL_API}/users/search`,
          {
            filters: search,
            page: page,
            size: size,
          },
          {
            withCredentials: true,
          }
        );
        return res.data;
      }
      const res = await axios.get(
        `${URL_API}/users?page=${page}&size=${size}`,
        {
          withCredentials: true,
        }
      );
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${URL_API}/users`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values, { rejectWithValue }) => {
    try {
      const { id, password } = values;
      if (password === "") delete values.password;
      const res = await axios.put(`${URL_API}/users/${id}`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (values, { rejectWithValue }) => {
    try {
      const { id } = values;
      const res = await axios.delete(`${URL_API}/users/${id}`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "user/changeUserPassword",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${URL_API}/users/change-password`, values, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
