import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import {
  getCompanyList,
  createCompany,
  updateCompany,
  deleteCompany,
} from "../../services/company.service";

const initialState = {
  isLoading: false,
  list: [],
  search: {},
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 15,
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearMessages(state, actions) {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        isLoading: false,
      };
    },
    setCurrentPageList(state, actions) {
      return { ...state, currentPageList: actions.payload };
    },
    setSearchParams(state, actions) {
      return { ...state, search: actions.payload, currentPageList: 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data.list,
          currentPageList: data.meta.page,
          totalPageList: data.meta.total_page,
          sizePageList: data.meta.size,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getCompanyList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });

    builder.addCase(createCompany.pending, (state, action) => {
      toast.loading(`Creando empresa ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(createCompany.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(createCompany.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(updateCompany.pending, (state, action) => {
      toast.loading(`Actualizando empresa ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });

    builder.addCase(deleteCompany.pending, (state, action) => {
      toast.loading(`Eliminando empresa ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
        };
      }
      return state;
    });
    builder.addCase(deleteCompany.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
      };
    });
  },
});

export const { setCurrentPageList, setSearchParams, clearMessages } =
  companySlice.actions;
export default companySlice.reducer;
