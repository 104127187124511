import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./routes/App";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

/** Redux */
import store from "./store/store";

ReactDOM.render(
  <React.StrictMode>
    {/* <Suspense fallback={<p>Cargando!!!!!!!!!!!!</p>}> */}
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById("app")
);
