import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_API } from "../shared/consts";
import { redirectLogin } from "../utils/functions.util";

/** THUNKS */
export const getMessengerList = createAsyncThunk(
  "messenger/getMessengerList",
  async (values, { rejectWithValue }) => {
    try {
      const { page, size, search } = values;
      const filter = Object.entries(search);
      if (filter.length) {
        const res = await axios.post(
          `${URL_API}/users/search`,
          {
            filters: search,
            page: page,
            size: size,
          },
          {
            withCredentials: true,
          }
        );
        return res.data;
      }
      return null;
    } catch (error) {
      redirectLogin(error?.response?.status);
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
