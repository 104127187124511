import { createSlice } from "@reduxjs/toolkit";

function getWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}

const initialState = {
  toggleNavMenu: true, //getWidth() < 600,
  typePassword: true,
};

/** SLICES */
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    onToggleNavMenu: (state, actions) => {
      return {
        ...state,
        toggleNavMenu: !state.toggleNavMenu,
      };
    },
    onChangeTypePassword: (state, actions) => {
      return {
        ...state,
        typePassword: !state.typePassword,
      };
    },
  },
});

export const { onToggleNavMenu, onChangeTypePassword } = appSlice.actions;
export default appSlice.reducer;
