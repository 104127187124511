import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import {
  changeShipmentStatus,
  createShipment,
  deleteShipment,
  getShipmentList,
  updateShipment,
  uploadShipments,
  downloadShipments,
  bulkChangeShipmentStatus,
} from "../../services/shipment.service";

const initialState = {
  isLoading: false,
  list: [],
  search: {},
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 25,
  errorMsg: null,
  successMsg: null,
  checkList: {},
};

/** SLICES */
export const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    clearMessages(state, actions) {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        isLoading: false,
        checkList: {},
      };
    },
    setCurrentPageList(state, actions) {
      return { ...state, checkList: {}, currentPageList: actions.payload };
    },
    setSearchParams(state, actions) {
      return {
        ...state,
        checkList: {},
        search: actions.payload,
        currentPageList: 1,
      };
    },
    setCheckList(state, actions) {
      return {
        ...state,
        checkList: actions.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipmentList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getShipmentList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data.list,
          currentPageList: data.meta.page,
          totalPageList: data.meta.total_page,
          sizePageList: data.meta.size,
          errorMsg: null,
          successMsg: null,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(getShipmentList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(createShipment.pending, (state, action) => {
      toast.loading(`Creando envío ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(createShipment.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(createShipment.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(updateShipment.pending, (state, action) => {
      toast.loading(`Actualizando envío ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(updateShipment.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(updateShipment.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(deleteShipment.pending, (state, action) => {
      toast.loading(`Eliminando envío ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(deleteShipment.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(deleteShipment.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(changeShipmentStatus.pending, (state, action) => {
      toast.loading(`Cambiando el estado del envío ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(changeShipmentStatus.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(changeShipmentStatus.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(bulkChangeShipmentStatus.pending, (state, action) => {
      toast.loading(`Cambiando los estados de los envíos ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(bulkChangeShipmentStatus.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(bulkChangeShipmentStatus.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(uploadShipments.pending, (state, action) => {
      toast.loading(`Cargando envíos masivamente ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(uploadShipments.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.message;
        toast.dismiss();
        toast.success(`${data}`, OPTIONS_TOAST);
        return {
          ...state,
          successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(uploadShipments.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });

    builder.addCase(downloadShipments.pending, (state, action) => {
      toast.loading(`Cargando envíos masivamente ...`, OPTIONS_TOAST);
      return { ...state, isLoading: true };
    });
    builder.addCase(downloadShipments.fulfilled, (state, action) => {
      if (action.payload) {
        // const data = action.payload.message;
        toast.dismiss();
        // toast.success(`${data}`, OPTIONS_TOAST);

        const blob = new Blob([action.payload], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "archivo.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        return {
          ...state,
          // successMsg: data,
          errorMsg: null,
          isLoading: false,
          checkList: {},
        };
      }
      return state;
    });
    builder.addCase(downloadShipments.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        successMsg: null,
        errorMsg: action.payload,
        checkList: {},
      };
    });
  },
});

export const {
  setCurrentPageList,
  setSearchParams,
  clearMessages,
  setCheckList,
} = shipmentSlice.actions;
export default shipmentSlice.reducer;
