import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import { getCoordinates } from "../../services/googleMaps.service";

const initialState = {
  isLoading: false,
  list: [],
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const googleMapsSlice = createSlice({
  name: "googleMaps",
  initialState,
  reducers: {
    clearMessages(state, actions) {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoordinates.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getCoordinates.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.results;
        return {
          ...state,
          isLoading: false,
          list: data,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getCoordinates.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });
  },
});

export const { clearMessages } = googleMapsSlice.actions;
export default googleMapsSlice.reducer;
