export const parseValueSelect = (names = ["id", "name"], data = []) => {
  if (data.length <= 0) return [];
  if (names[0] == "" || names[1] == "") return [];
  const keys = names[1].split("|");
  const value = names[0];
  return data.map((item) => {
    return {
      label: keys
        .map((s) => String(item[`${s}`]).trim())
        .flat()
        .join(" | "),
      value: item[`${value}`],
    };
  });
};

export const parseFiltersSearch = (values) => {
  const fields = Object.entries(values);
  return fields.reduce((acc, field) => {
    if (Array.isArray(field[1]) && field[1].length) {
      if (
        field[1].length === 2 &&
        field[1].every((val) =>
          /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(val)
        )
      ) {
        acc.push({
          property: field[0].replace(/\|/g, "."),
          operator: "BETWEEN",
          values: field[1],
        });
      } else {
        acc.push({
          property: field[0].replace(/\|/g, "."),
          operator: "IN",
          values: field[1],
        });
      }
      return acc;
    } else if (
      (typeof field[1] === "string" || field[1] instanceof String) &&
      field[1] !== ""
    ) {
      acc.push({
        property: field[0].replace(/\|/g, "."),
        operator: "LIKE",
        values: [field[1]],
      });
      return acc;
    } else {
      return acc;
    }
  }, []);
};

export const redirectLogin = (status) => {
  if (status == 401 || status == 403) {
    window.location = "/login";
  }
};
