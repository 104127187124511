import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL_API } from "../shared/consts";
import { redirectLogin } from "../utils/functions.util";

/** THUNKS */
export const authUser = createAsyncThunk(
  "auth/authUser",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${URL_API}/auth`,
        {
          username: values.username,
          password: values.password,
        },
        { withCredentials: true }
      );
      const response = res.data;
      return response;
    } catch (error) {      
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
