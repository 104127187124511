import moment from "moment/moment";
import jwtDecode from "jwt-decode";
import { TAG_TOKEN } from "../shared/consts";

export const decodeToken = (token) => {
  return jwtDecode(token);
};
export const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  return decodedToken.exp <= moment().unix();
};
export const setToken = (token) => {
  window.localStorage.setItem(TAG_TOKEN, token);
};
export const getToken = () => {
  return window.localStorage.getItem(TAG_TOKEN);
};
export const removeToken = () => {
  window.localStorage.removeItem(TAG_TOKEN);
};
