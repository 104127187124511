import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import roleSlice from "./slices/role.slice";
import userSlice from "./slices/user.slice";
import companySlice from "./slices/company.slice";
import ubigeoSlice from "./slices/ubigeo.slice";
import shipmentSlice from "./slices/shipment.slice";
import shipmentTypeSlice from "./slices/shipmentType.slice";
import productSlice from "./slices/product.slice";
import googleMapsSlice from "./slices/googleMaps.slice";
import appSlice from "./slices/app.slice";
import paymentMethodSlice from "./slices/paymentMethod.slice";
import shipmentStatusSlice from "./slices/shipmentStatus.slice";
import messengerSlice from "./slices/messenger.slice";
import documentTypeSlice from "./slices/documentType.slice";

export default configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    messenger: messengerSlice,
    role: roleSlice,
    company: companySlice,
    ubigeo: ubigeoSlice,
    shipment: shipmentSlice,
    shipmentType: shipmentTypeSlice,
    documentType: documentTypeSlice,
    product: productSlice,
    paymentMethod: paymentMethodSlice,
    googleMaps: googleMapsSlice,
    app: appSlice,
    shipmentStatus: shipmentStatusSlice,
  },
});
