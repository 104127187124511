import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GEOCODING_API_KEY } from "../shared/consts";
/** THUNKS */
export const getCoordinates = createAsyncThunk(
  "googleMaps/coordinates",
  async (values, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${values}&key=${GEOCODING_API_KEY}`
      );
      const response = res.data;
      return response;
    } catch (error) {
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(message);
    }
  }
);
