import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import { getMessengerList } from "../../services/messenger.service";

const initialState = {
  isLoading: false,
  list: [],
  search: {},
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 15,
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMessengerList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getMessengerList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data.list,
          currentPageList: data.meta.page,
          totalPageList: data.meta.total_page,
          sizePageList: data.meta.size,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getMessengerList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });
  },
});

// export const {
//   clearMessages,
//   setUser,
// } = userSlice.actions;
export default messengerSlice.reducer;
