import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { OPTIONS_TOAST } from "../../shared/consts";
import { authUser } from "../../services/auth.service";
import { getToken, removeToken, setToken } from "../../helpers/auth.helper";

const initialState = {
  token: getToken() || null,
  isLogging: false,
  errorMsg: null,
  successMsg: null,
};

/** SLICES */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearToken: (state, actions) => {
      removeToken();
      return {
        ...state,
        token: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.pending, (state, action) => {
      toast.loading(`Iniciando sesión ...`, OPTIONS_TOAST);
      return { ...state, isLogging: true };
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      if (action.payload) {
        const token = action.payload.data.access_token;
        setToken(token);
        return {
          ...state,
          token: token,
          isLogging: false,
          successMsg: "Usuario se loggeo correctamente",
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(authUser.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        token: null,
        isLogging: false,
        errorMsg: action.payload,
      };
    });
  },
});

export const { clearToken } = authSlice.actions;
export default authSlice.reducer;
