import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getShipmentTypeList } from "../../services/shipmentType.service";
import { OPTIONS_TOAST } from "../../shared/consts";

const initialState = {
  isLoading: false,
  list: [],
  currentPageList: 1,
  totalPageList: 1,
  sizePageList: 1,
  errorMsg: null,
};

/** SLICES */
export const shipmentTypeSlice = createSlice({
  name: "shipmentType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShipmentTypeList.pending, (state, action) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getShipmentTypeList.fulfilled, (state, action) => {
      if (action.payload) {
        const data = action.payload.data;
        return {
          ...state,
          isLoading: false,
          list: data,
          errorMsg: null,
        };
      }
      return state;
    });
    builder.addCase(getShipmentTypeList.rejected, (state, action) => {
      toast.dismiss();
      toast.error(`${action.payload}`, OPTIONS_TOAST);
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    });
  },
});

// export const { } = roleSlice.actions;
export default shipmentTypeSlice.reducer;
